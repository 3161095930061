<template>
    <div ref="wrapperEl"
         :class="['sim-popup-card', `sim-popup-card--${size}`, {
             'sim-popup-card--alt': alternate,
             'sim-popup-card--shadow': !noShadow && !alternate,
         }]"
    >
        <Transition :name="transition" appear>
            <div v-show="isShown"
                 ref="popupCardEl"
                 class="sim-popup-card__el"
                 :style="{
                     maxHeight: maxHeightStyle,
                 }"
            >
                <svg v-if="!noTip"
                     xmlns="http://www.w3.org/2000/svg"
                     aria-hidden="true"
                     :width="svgStyles.width"
                     :height="svgStyles.height"
                     :viewBox="svgStyles.viewBox"
                     class="sim-popup-card__tip"
                     :class="{
                         'sim-popup-card__tip--bottom': tipLocation.includes('bottom'),
                         'sim-popup-card__tip--right': tipLocation.includes('right'),
                         'sim-popup-card__tip--center': tipLocation.includes('middle'),
                     }"
                >
                    <polygon :points="svgStyles.points" fill="white" />
                </svg>

                <div class="sim-popup-card__content-wrapper">
                    <div class="sim-popup-card__content" :class="cardClass">
                        <!--  @slot Content  -->
                        <slot />
                    </div>
                </div>
            </div>
        </Transition>
    </div>
</template>

<script lang="ts" setup>
import { SymbolUiPopupCard, type UiPopupCardProvide } from '@theme-types/components/UiPopupCard'

const {
    tipLocation = 'top-left',
    size = 'md',
    noShadow,
    alternate,
    ignoreElements,
    noTip,
    cardClass,
    lockScroll,
    transition = 'slide-down',
} = defineProps<{
    tipLocation?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right' | 'top-middle' | 'bottom-middle'
    size?: 'sm' | 'md' | 'lg'
    noShadow?: boolean
    alternate?: boolean
    ignoreElements?: (HTMLElement | null)[]
    noTip?: boolean
    cardClass?: string
    lockScroll?: boolean
    transition?: 'slide-up' | 'slide-down'
}>()

const isShown = defineModel<boolean>()

const popupCardEl = ref<HTMLDivElement | null>(null)
useManagePopupOpening(isShown, {
    closeCallback: closePopup,
    lockScroll: lockScroll,
    closeOnClickOutside: popupCardEl,
    ignoreElements: ignoreElements ?? [],
})

function closePopup() {
    isShown.value = false
}


// TODO: replace this with floating ui solution later
const wrapperEl = ref<HTMLDivElement | null>(null)
const maxHeightStyle = ref<string | undefined>(undefined)
onMounted(() => {
    if (!wrapperEl.value) return
    const offsetFromTop = wrapperEl.value.getBoundingClientRect().top
    maxHeightStyle.value = `calc(100vh - ${offsetFromTop}px - 16px)`

})

const svgStyles = computed(() => {
    if (alternate) {
        return {
            width: 20,
            height: 10,
            viewBox: '0 0 20 15',
            points: '3,3 20,20 3,20',
        }
    }

    return {
        width: 20,
        height: 10,
        viewBox: '0 0 20 10',
        points: '10,0 20,10 0,10',
    }
})

provide<UiPopupCardProvide>(SymbolUiPopupCard, {
    closePopup,
})

</script>

<style lang="scss" scoped>
$tip-edge-offset: 15%;

.sim-popup-card {
    z-index: 2;
}

.sim-popup-card__el {
    display: flex;
    flex-direction: column;

    box-sizing: border-box;

    &.slide-down-enter-active,
    &.slide-down-leave-active,
    &.slide-up-enter-active,
    &.slide-up-leave-active {
        transition: opacity $sim-trans-time-short $sim-timing, transform $sim-trans-time-normal $sim-timing;
    }

    &.slide-down-enter-from,
    &.slide-down-leave-to {
        opacity: 0;
        transform: translateY(-0.5rem);
    }

    &.slide-up-enter-from,
    &.slide-up-leave-to {
        opacity: 0;
        transform: translateY(0.5rem);
    }
}

.sim-popup-card__content {
    background-color: white;

    padding: 0.65rem 1rem;

    overflow-y: auto;
}

.sim-popup-card__content-wrapper {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 0.5rem;
}

.sim-popup-card--md .sim-popup-card__content {
    padding: 1.25rem 1.5rem;
}

.sim-popup-card--alt .sim-popup-card__content {
    padding: 0.75rem 0.5rem;
}

.sim-popup-card--alt .sim-popup-card__content-wrapper {
    border-radius: 0.125rem;
}

.sim-popup-card--shadow .sim-popup-card__el {
    filter: drop-shadow(0 9px 19px rgb(8 15 61 / 15%));
}

.sim-popup-card__tip {
    position: absolute;
    top: 1px;
    left: $tip-edge-offset;

    transform: translateY(-100%);
}

.sim-popup-card--alt .sim-popup-card__tip {
    stroke-linejoin: round;
    stroke: white;
    stroke-width: 0.125rem;
}

.sim-popup-card__tip--bottom {
    top: initial;
    bottom: 0;
    transform: translateY(100%) scaleX(-100%) rotate(180deg);

    &.sim-popup-card__tip--center {
        transform: translateX(-50%) translateY(100%) scaleX(-100%) rotate(180deg);
    }
}

.sim-popup-card__tip--right {
    left: initial;
    right: $tip-edge-offset;
}

.sim-popup-card__tip--center {
    right: initial;
    left: 50%;
    transform: translateX(-50%) translateY(-100%);
}

</style>
